import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="toggler"
export default class extends Controller {

  static targets = [ "select", "fields", "required" ]

  connect() {
    // Select2 does not use ‘native’ events
    // https://psmy.medium.com/rails-6-stimulus-and-select2-de4a4d2b59e4
    $(this.selectTarget).on('select2:select', function() {
      let event = new Event('change', { bubbles: true })
      this.dispatchEvent(event)
    })
  }

  add_new_state() {
    const visible = $(this.selectTarget).val() == '0'
    $(this.fieldsTarget).toggle(visible)
    $(this.fieldsTarget).find('[data-toggler-target="required"]').prop("required", visible);
  }

}
