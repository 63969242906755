import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="editable_task_row"
export default class extends Controller {

  static targets = [ "metric", "dueDate" ]

  static values = { taskId: String }

  connect() {
    this.setup_editable_row();
  }

  setup_editable_row() {
    $(this.element).editable({
      keyboard: true,
      dblclick: true,
      button: true,
      maintainWidth: true,
      buttonSelector: ".js-edit",
      dropdowns: {
        metric_id: $('#metric-options').data('options'),
        status: [ 'not_started', 'in_progress', 'done', 'archived' ]
      },
      edit: (values) => { this.edit(values) },
      save: (values) => { this.save(values) },
      cancel: () => { this.cancel() }
    });
  }

  edit(values) {
    this.fill_out_metric_select(values);
    this.setup_date_input();
    this.apply_edit_styling_to_elements();
  }

  fill_out_metric_select(values) {
    const $select = $(this.metricTarget).find('select');
    $select.find('option').each(function() {
      const option = $(this).text().match(/(?<name>.*),(?<id>\d+)$/);
      $(this).text(option.groups.name);
      $(this).attr('value', option.groups.id);
      if (option.groups.name == values['metric_id']) {
        $select.val(option.groups.id).change();
      }
    });
  }

  setup_date_input() {
    const $date_input = $(this.dueDateTarget).find('input');
    $date_input.attr('type', 'date');
  }

  apply_edit_styling_to_elements() {
    $(this.element).find('.js-edit .js-edit-icon').toggleClass('tio-edit tio-done');
    $(this.element).find('td[data-field] input, td[data-field] select').addClass('form-control form-control-sm');
  }

  save(values) {
    this.display_selected_metric(values);
    this.do_save_request(values);
    this.remove_edit_styling_from_elements();
  }

  display_selected_metric(values) {
    const selected_metric = $('#metric-options').data('options').find(element => element[1] == values['metric_id']);
    if (selected_metric != undefined) {
      $(this.metricTarget).text(selected_metric[0]);
    }
  }

  do_save_request(values) {
    $.ajax({
      url:  '/admineo/tasks/' + this.taskIdValue,
      type: 'PUT',
      data: { 'task': values }
    });
  }

  cancel() {
    this.remove_edit_styling_from_elements();
  }

  remove_edit_styling_from_elements() {
    $(this.element).find('.js-edit .js-edit-icon').toggleClass('tio-done tio-edit');
  }

}
