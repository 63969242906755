require("@rails/ujs").start()
import "@hotwired/turbo-rails"
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("channels")

require("chartkick")
require("chart.js/dist/Chart.bundle")

require('../src/appear/dist/appear')

import 'bootstrap/dist/js/bootstrap'
import "../src/theme.min"
import "../src/hs-navbar-vertical-aside/hs-navbar-vertical-aside.min"

import 'select2'

import 'table-edits/build/table-edits.min'

import 'jquery-ui/ui/version'
import 'jquery-ui/ui/data'
import 'jquery-ui/ui/plugin'
import 'jquery-ui/ui/scroll-parent'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/mouse'
import 'jquery-ui/ui/widgets/draggable'
import 'jquery-ui/ui/widgets/droppable'
import 'jquery-ui/ui/widgets/sortable'

import 'datatables'

require('jquery-validation/dist/jquery.validate.min')

require('cocoon-js')

import "../controllers"

require("../src/theme-custom.js")

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)
const flagImages = require.context('../stylesheets/flag-icon-css/flags/1x1', true)
const flagImagePath = (name) => flagImages(name, true)

import "../stylesheets/application.scss"

import "../qualification_handshake"
