import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="airwallex"
export default class extends Controller {

  static values = {
    actionUrl: String,
    redirectUrl: String
  }

  start() {
    $.ajax({
      url: this.actionUrlValue,
      type: 'PUT',
      success: function (result) {
        window.location.replace(this.redirectUrlValue);
      },
      error: function() {
        console.log('Error');
      }
    });
  }

}
