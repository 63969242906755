import HSUnfold from "../src/hs-unfold/src/js/hs-unfold";
import HSScrollspy from '../src/hs-scrollspy/src/js/hs-scrollspy';
import HSStickyBlock from '../src/hs-sticky-block/src/js/hs-sticky-block';
import '../src/hs-sticky-header/src/hs.sticky-header.js';
import HSStepForm from '../src/hs-step-form/src/js/hs-step-form'

document.addEventListener("turbo:load", function() {
  // initialization of navbar vertical navigation
  var sidebar = $('.js-navbar-vertical-aside').hsSideNav();

  // initialization of tooltip in navbar vertical menu
  $('.js-nav-tooltip-link').tooltip({ boundary: 'window' })

  $(".js-nav-tooltip-link").on("show.bs.tooltip", function(e) {
    if (!$("body").hasClass("navbar-vertical-aside-mini-mode")) {
      return false;
    }
  });

  // initialization of unfold
  $('.js-hs-unfold-invoker').each(function () {
    var unfold = new HSUnfold($(this)).init();
  });

  $('.js-chart').each(function () {
    $.HSCore.components.HSChartJS.init($(this));
  });

  $('.js-sticky-block').each(function () {
    var stickyBlock = new HSStickyBlock($(this), {
      targetSelector: $('#header').hasClass('navbar-fixed') ? '#header' : null
    }).init();
  });

  if(document.querySelector('.scroll-spied') != null) {
    // initialization of scroll nav
    var scrollspy = new HSScrollspy($('body'), {
      // !SETTING "resolve" PARAMETER AND RETURNING "resolve('completed')" IS REQUIRED
      beforeScroll: function(resolve) {
        if (window.innerWidth < 992) {
          $('#navbarVerticalNavMenu').collapse('hide').on('hidden.bs.collapse', function () {
            return resolve('completed');
          });
        } else {
          return resolve('completed');
        }
      }
    }).init();
  }

  if(document.querySelector('#revenueBarChart') != null) {
    var updatingBarChart = $.HSCore.components.HSChartJS.init($('#revenueBarChart'));
    updatingBarChart.options.tooltips = {
      mode: 'label',
      callbacks: {
        afterTitle: function() {
          window.total = 0;
        },
        label: function(tooltipItem, data) {
          var source = data.datasets[tooltipItem.datasetIndex].label;
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          window.total += value;
          return source + ": " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        },
        footer: function() {
          return "TOTAL: " + window.total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
      }
    }
    updatingBarChart.update();
  }

  $('.quill-editor-content').each(function () {
    var $field  = $(this).closest('.form-group').find('input[type="hidden"]');
    var $form   = $(this).closest('form');
    var $editor = $.HSCore.components.HSQuill.init(this);
    $form.submit(function() {
      $field.val($editor.root.innerHTML);
    });
  });

  if(document.querySelector('#wholesale_form_fields') != null) {
    var is_wholesale_checked = $("#uses_wholesale_toggle").is(':checked');
    $("#wholesale_form_fields").toggle(is_wholesale_checked);
    $("#uses_wholesale_toggle").on("click", function(){
      $("#wholesale_form_fields").toggle($("#uses_wholesale_toggle").is(':checked'));
    });
  }

  if(document.querySelector('#date_range') != null) {
    $('#date_range').on('change', function(){
      key = encodeURIComponent('date_range');
      value = encodeURIComponent(document.getElementById("date_range").value);

      // kvp looks like ['key1=value1', 'key2=value2', ...]
      var kvp = document.location.search.substr(1).split('&');
      let i=0;

      for(; i<kvp.length; i++){
        if (kvp[i].startsWith(key + '=')) {
            let pair = kvp[i].split('=');
            pair[1] = value;
            kvp[i] = pair.join('=');
            break;
        }
      }

      if(i >= kvp.length){
        kvp[kvp.length] = [key,value].join('=');
      }

      // can return this or...
      let params = kvp.join('&');

      // reload page with new params
      document.location.search = params;
    });
  }

  $('.js-validate').each(function() {
    $.HSCore.components.HSValidation.init($(this));
  });

  $('.js-step-form').each(function () {
    var stepForm = new HSStepForm($(this), {
      finish: function() {
        $("#validationFormProgress").hide();
        $("#validationFormContent").hide();
        $("#validationFormSuccessMessage").show();
      }
    }).init();
  });

  $('.js-circle').each(function () {
    var circle = $.HSCore.components.HSCircles.init($(this));
  });

  $('.js-select2-custom').each(function () {
    $.HSCore.components.HSSelect2.init($(this));
  });
  $(document).on('cocoon:after-insert', function() {
    $('.js-select2-custom').each(function () {
      $.HSCore.components.HSSelect2.init($(this));
    });
  });

  $('.js-enable-select2').select2();

  $(".custom-checkbox input").on("change", function(){
    if(document.getElementById('customCheck1').checked && document.getElementById('customCheck2').checked && document.getElementById('customCheck3').checked && document.getElementById('customCheck4').checked && document.getElementById('customCheck5').checked && document.getElementById('customCheck6').checked && document.getElementById('customCheck7').checked) {
      $("#po_button_next").show();
    } else {
      $("#po_button_next").hide();
    }
  });

  // Pillar metrics carousel initialization
  $('.pillar-metrics-overview-item').first().addClass('active');

  $('.datatableWithFilter').each(function () {
    const $table     = $(this)
    const $container = $table.closest('.card')
    const datatableWithFilter = $.HSCore.components.HSDatatables.init($table, {
      language: {
        zeroRecords: '<div class="text-center p-4">' +
                     '<p class="mb-0">No data to show</p>' +
                      '</div>'
      }
    });

    $container.find('.js-datatable-filter').on('change', function() {
      var $this = $(this),
          elVal = $this.val(),
          targetColumnIndex = $this.data('target-column-index');

      datatableWithFilter.column(targetColumnIndex).search(elVal).draw();
    });
  });

  if(document.querySelector('.task_insight_update') != null) {
    $('.task_insight_update select').on("change", function(event) {
      $(this).closest('form').submit();
    });
  }

  if(document.querySelector('.insight-line') != null) {
    $('.insight-line').each(function() {
      $element = $(this)
      title = $element.data('title');
      content = $element.data('content');
      $element.popover({
        title: title,
        content: content,
        html: true,
        container: 'body',
        placement: 'top',
        trigger: 'focus'
      });
    });
  }

  if(document.querySelector('.new_key_result') != null) {
    $('#other-krs').on("change", function(event) {
      const $option = $("#other-krs option:selected");
      const name    = $option.text();
      const editor  = document.querySelector('.quill-editor-content').__quill
      const notes   = $option.data('notes');
      const delta   = editor.clipboard.convert(notes)
      const metric  = $option.data('metric');
      const pillar  = $option.data('pillar');
      $('#key_result_name').val(name);
      editor.setContents(delta, 'silent')
      $('#key_result_metric_id').val(metric).change();
      $('#key_result_pillar_id').val(pillar).change();
      $("#other-krs").val('').trigger('change.select2');
    });
  }

  if(document.querySelector('.new_todo') != null) {
    $('#other-krs').on("change", function(event) {
      const $option = $("#other-krs option:selected");
      const name    = $option.text();
      const editor  = document.querySelector('.quill-editor-content').__quill
      const notes   = $option.data('notes');
      const delta   = editor.clipboard.convert(notes)
      const metric  = $option.data('metric');
      const pillar  = $option.data('pillar');
      $('#todo_name').val(name);
      editor.setContents(delta, 'silent')
      $('#todo_metric_id').val(metric).change();
      $('#todo_pillar_id').val(pillar).change();
      $("#other-krs").val('').trigger('change.select2');
    });
  }

  if(document.querySelector('#sort-button') != null) {
    $('#sort-button').click(function() {
      $('#objectives-list').hide();
      $('#objectives-toolbar').hide();
      $('#objectives-sortable').show();
      $('#objectives-sortable ol').sortable({
        axis: "y",
        cursor: 'ns-resize',
        tolerance: "pointer"
      });
    })
    $('#cancel-sortable-button').click(function() {
      $('#objectives-list').show();
      $('#objectives-toolbar').show();
      $('#objectives-sortable').hide();
    })
  }

  if(document.querySelector('#new_vendor_form') != null) {
    $(document).on("keydown", "form", function(event) {
      return event.key != "Enter";
    });
  }

  $('.custom-file-input').each(function() {
    $(this).change(function(event) {
      var fileName = event.target.files[0].name;
      var nextSibling = event.target.nextElementSibling;
      nextSibling.innerText = fileName;
    })
  })

  if(document.querySelector('#newIntroductionModal') != null) {
    $('#introduction_service_id').change(function() {
      if ($(this).find('option:selected').text() == 'Other') {
        $('#help-for-other').show()
        $('#introduction_notes').attr('required', true)
        $('label[for="introduction_notes"]').text('Add some details')
      } else {
        $('#help-for-other').hide()
        $('#introduction_notes').attr('required', false)
        $('label[for="introduction_notes"]').text('Add some details (optional)')
      }
    })
  }

  if (document.querySelector('form#new_todo') != null) {
    $('form#new_todo').on('cocoon:after-insert', function() {
      $('.item-type-select').select2().change(function() {
        const new_value = $(this).val();
        var options;
        switch(new_value) {
          case 'Resource':
            options = $('#item-id-options').data('resources')
            break;
          case 'Insight':
            options = $('#item-id-options').data('insights')
            break;
          case 'Event':
            options = $('#item-id-options').data('events')
        }
        var $select = $(this).closest('.nested-fields').find('.item-id-select')
        $select.empty().append(options)
        $.HSCore.components.HSSelect2.init($select);
      })
    })
  }

  if (document.querySelector('.corrections-container') != null) {
    $('.corrections-container').on('input', '.corrected-amount', function() {
      let required = $(this).val() !== "";
      $(this).closest('tr').find('.corrected-amount-proof').prop('required', required);
    })
  }

  if (document.querySelector('.nfcca-application-container') != null) {
    $('.nfcca-application-container').on('click', '.start-application', function() {
      const offerId = $(this).data('offer-id');
      const url = `https://www.airwallex.com/join/clearco-us`

      $.ajax({
        url: `/offers/${offerId}/airwallex`,
        type: 'PATCH',
        success: function(data) {
          window.location.href = url;
        },
        error: function(error) {
          console.log('error')
        }
      });
    })
  }

  if (document.querySelector('.first-valuation-container') != null) {
    const params = window.location.search.substring(1)
    if(params === 'category_error=true') {
      $('##category').modal('show');
    } else if(params === 'ue_error=true') {
      $('#unit-economics').modal('show');
    }
  }

  if (document.querySelector("#cancelAccountModal") != null) {

    function isScheduled(e) {
      return e.data.event &&
        e.data.event.indexOf('calendly.event_scheduled') === 0;
    };

    window.addEventListener(
      'message',
      function(e) {
        if (isScheduled(e)) {
          const calendlyUri = encodeURIComponent(e.data.payload.event.uri);
          window.location = "/cancel_account?event_uri=" + calendlyUri;
        }
      }
    );

  }

});
