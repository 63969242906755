import Rails from '@rails/ujs';

let windowRef;

const loadingElementHTML = '<div style="display:flex;justify-content:center;align-items:center;"><div class="spinner-border spinner-border-sm" role="status"><span class="sr-only">Loading...</span></div></div>';

async function completeHandshake(data) {
  await Rails.ajax({
    type: 'POST',
    dataType: 'script',
    url: '/diaas/account',
    beforeSend: (xhr, options) => {
      xhr.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
      options.data = JSON.stringify(data);
      return true;
    },
  });
}

const openHandshakeWindow = (nextUrl, organization_key) => {
  windowRef.location.href = nextUrl;
  windowRef.focus();

  window.addEventListener('message', event => {
    let data;
  
    if (event.origin !== new URL(nextUrl).origin || !event.data || event.data.error !== null) {
      return;
    }
  
    data = { ...event.data };
    data.organization_key = organization_key;
    windowRef.close();
    completeHandshake(data);
  }, false);
};

window.addDiaasConnectListeners = () => {
  document.querySelectorAll('.qualify-link-account-btn-disconnected').forEach(elem => {
    elem.addEventListener('click', event => {
      elem.innerHTML = loadingElementHTML;
    });
  });
  document.querySelector('#diaas-link-accounts').addEventListener('ajax:success', event => {
    const [data] = event.detail;
    if (data.next_url) {
      windowRef = window.open('', '_blank', 'toolbar=0,location=0,menubar=0');
      openHandshakeWindow(data.next_url, data.organization_key);
    }
  });
};