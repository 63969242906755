import { Controller } from "@hotwired/stimulus"
import Rails from '@rails/ujs';

// Connects to data-controller="okrs"
export default class extends Controller {

  connect() {
    this.hide_modal_after_refresh();
    this.init_select2();
    this.init_datatable();
  }

  hide_modal_after_refresh() {
    document.addEventListener("turbo:before-fetch-response", function(event) {
      $(event.target).closest('.modal').modal('hide')
    });
  }

  init_select2() {
    document.addEventListener("turbo:frame-render", function(event) {
      $('.js-select2-custom').each(function () {
        $.HSCore.components.HSSelect2.init($(this));
      });
    });
  }

  init_datatable() {
    $('.datatableWithFilterTurbo').each(function() {
      const $table     = $(this)
      const $container = $table.closest('.card');
      const datatableWithFilter = $.HSCore.components.HSDatatables.init($table, {
        language: {
          zeroRecords: '<div class="text-center p-4">' +
                         '<p class="mb-0">No data to show</p>' +
                        '</div>'
        }
      });
    });
  }

  edit_pillar(event) {
    const {object, data} = this.get_object_and_data(event);
    $('#editOkrPillar input[name="name"]').val(data.pillar_name);
    this.set_update_url_and_show_modal('editOkrPillar', object, data.pillar_id)
  }

  edit_objective(event) {
    const {object, data} = this.get_object_and_data(event);
    $('#editOkrObjective input[name="title"]').val(data.objective_title);
    $('#editOkrObjective select').val(data.pillar_id);
    $('#editOkrObjective input[type="checkbox"][name="active"]').prop('checked', data.objective_active);
    this.set_update_url_and_show_modal('editOkrObjective', object, data.objective_id)
  }

  edit_task_template(event) {
    const {object, data} = this.get_object_and_data(event);
    $('#editOkrTaskTemplate input[name="name"]').val(data.task_template_name);
    $('#editOkrTaskTemplate select').val(data.metric_id);
    this.set_update_url_and_show_modal('editOkrTaskTemplate', object, data.task_template_id)
  }

  edit_insight(event) {
    const {object, data} = this.get_object_and_data(event);
    $('#editOkrInsight input[name="title"]').val(data.insight_title);
    $('#editOkrInsight textarea').text(data.insight_notes);
    this.set_update_url_and_show_modal('editOkrInsight', object, data.insight_id)
  }

  edit_resource(event) {
    const {object, data} = this.get_object_and_data(event);
    $('#editOkrResource input[name="title"]').val(data.resource_title);
    $('#editOkrResource input[name="url"]').val(data.resource_url);
    $('#editOkrResource select').val(data.resource_kind);
    this.set_update_url_and_show_modal('editOkrResource', object, data.resource_id)
  }

  set_update_url_and_show_modal(modal, object, id) {
    $(`#${modal} form`).attr('action', `/admineo/okrs/update/${object}/${id}`);
    $(`#${modal}`).modal('show');
  }

  add_pillar(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#addOkr input[name="object"]').val('pillar');

    this.blank_pillar()
    this.blank_objective()
    this.blank_task_template()
    this.blank_insight()
    this.blank_resource()

    $('#addOkr').modal('show');
  }

  add_objective(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#addOkr input[name="object"]').val('objective');

    this.freeze_pillar(data)

    this.blank_objective()
    this.blank_task_template()
    this.blank_insight()
    this.blank_resource()

    $('#addOkr').modal('show');
  }

  add_task_template(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#addOkr input[name="object"]').val('task_template');

    this.freeze_pillar(data)
    this.freeze_objective(data)

    this.blank_task_template()
    this.blank_insight()
    this.blank_resource()

    $('#addOkr').modal('show');
  }

  add_insight(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#addOkr input[name="object"]').val('insight');

    this.freeze_pillar(data)
    this.freeze_objective(data)
    this.freeze_task_template(data)

    this.blank_insight()
    this.blank_resource()

    $('#addOkr').modal('show');
  }

  add_resource(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#addOkr input[name="object"]').val('resource');

    this.freeze_pillar(data)
    this.freeze_objective(data)
    this.freeze_task_template(data)
    this.freeze_insight(data)

    this.blank_resource()

    $('#addOkr').modal('show');
  }

  freeze_pillar(data) {
    $('#addOkr input[name="pillar_id"]').val(data.pillar_id);
    $('#addOkr input[name="pillar_name"]').val(data.pillar_name);
    $('#addOkr input[name="pillar_name"]').prop("disabled", true);
  }

  freeze_objective(data) {
    $('#addOkr input[name="objective_id"]').val(data.objective_id);
    $('#addOkr input[name="objective_title"]').val(data.objective_title);
    $('#addOkr input[name="objective_title"]').prop("disabled", true);
  }

  freeze_task_template(data) {
    $('#addOkr input[name="task_template_id"]').val(data.task_template_id);
    $('#addOkr input[name="task_template_name"]').val(data.task_template_name);
    $('#addOkr input[name="task_template_name"]').prop("disabled", true);
    $('#addOkr select[name="metric_id"]').val(data.metric_id);
    $('#addOkr select[name="metric_id"]').prop("disabled", true);
  }

  freeze_insight(data) {
    $('#addOkr select[name="insight_id"]').val(data.insight_id).change();
    $('#addOkr select[name="insight_id"]').prop("disabled", true);
  }

  blank_pillar() {
    $('#addOkr input[name="pillar_name"]').val('');
  }

  blank_objective() {
    $('#addOkr input[name="objective_title"]').val('');
  }

  blank_task_template() {
    $('#addOkr input[name="task_template_name"]').val('');
    $('#addOkr select[name="metric_id"]').val('').change();
  }

  blank_insight() {
    $('#addOkr select[name="insight_id"]').val('').change();
    $('#addOkr input[name="insight_title"]').val('');
    $('#addOkr textarea[name="insight_notes"]').html('');
  }

  blank_resource() {
    $('#addOkr select[name="resource_id"]').val('').change();
    $('#addOkr input[name="resource_title"]').val('');
    $('#addOkr input[name="resource_url"]').val('');
    $('#addOkr select[name="resource_kind"]').val('').change();
  }

  swap_objective(event) {
    const {object, data} = this.get_object_and_data(event);
    $('#swapObjectives input[name="current_objective"]').val(data.objective_title);
    $('#swapObjectives select option').prop('disabled', false);
    $('#swapObjectives select [value="' + data.objective_id + '"]').prop('disabled', true);
    $('#swapObjectives form').attr('action', `/admineo/okrs/swapping_objectives/${data.objective_id}`);
    $('#swapObjectives').modal('show');
  }

  sort_task_template(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#edit-sort-task-templates-container').load(`/admineo/okrs/sorting_task_templates/${data.objective_id}/edit`, function() {
      $('#sortTaskTemplates').modal('show');
      $('#task-template-sortable ol').sortable({
        axis: "y",
        cursor: 'ns-resize',
        tolerance: "pointer"
      });
    });
  }

  move_task_template(event) {
    const {object, data} = this.get_object_and_data(event);

    $('#edit-move-task-templates-container').load(`/admineo/okrs/moving_task_templates/${data.objective_id}/edit`, function() {
      $('#edit-move-task-templates-container .js-select2-custom').each(function () {
        $.HSCore.components.HSSelect2.init($(this));
      });
      $('#moveTaskTemplates select option').prop('disabled', false);
      $('#moveTaskTemplates select [value="' + data.objective_id + '"]').prop('disabled', true);
      $('#moveTaskTemplates').modal('show');
    });
  }

  get_object_and_data(event) {
    const $button = $(event.target);
    return {
      object: $button.closest('td').data('object-type'),
      data:   $button.closest('tr').data('object-data'),
    }
  }

  delete(event) {
    const ok = event.detail[0];
    if (!ok) return

    $('turbo-frame#okrs #fixed-flash').html('<div class="alert alert-soft-primary" role="alert">Deleting...</div>');
  }

  submit_form(event) {
    $(event.target).find(':disabled').each(function(e) {
      $(this).removeAttr('disabled');
    })

    const $button = $(event.target).find('input[type="submit"]');
    $button.prop('disabled', true);
    $button.val('Updating...');
  }

}
