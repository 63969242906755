import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="okr_toolbar"
export default class extends Controller {

  connect() {
    $(this.element).find('td').hover((event) => {
      if (this.needs_toolbar_rendering(event)) this.render_cell_toolbar(event);
    });
    this.color_cells();
  }

  needs_toolbar_rendering(event) {
    return !$(event.target).closest('td').find('.toolbar').length;
  }

  render_cell_toolbar(event) {
    const $cell       = $(event.target);
    const object_type = $cell.data('object-type');
    const id          = $cell.data('id');
    const parent_id   = $cell.data('parent-id');
    const html = `<div class='toolbar'>
                    <div class='btn-group' role='group'>
                      ${this.buttons(object_type, id, parent_id)}
                    </div>
                  </div>`;
    $cell.prepend(html);
  }

  buttons(object_type, id, parent_id) {
    return this.edit_button(object_type, id) +
           this.add_button(object_type, id, parent_id) +
           this.swap_button(object_type, id, parent_id) +
           this.sort_button(object_type, id, parent_id) +
           this.move_button(object_type, id, parent_id) +
           this.delete_button(object_type, id, parent_id) +
           this.link_to_backoffice(object_type, id, parent_id)
  }

  edit_button(object_type, id) {
    if (id === '') return '';

    return this.render_button(`edit_${object_type}`, 'edit', 'Edit', '');
  }

  add_button(object_type, id, parent_id) {
    if (parent_id === '' && object_type !== 'pillar') return '';

    return this.render_button(`add_${object_type}`, 'add', 'Add');
  }

  swap_button(object_type, id, parent_id) {
    if (object_type !== 'objective' || id === '') return '';

    return this.render_button(`swap_${object_type}`, 'swap-vs', 'Swap objectives');
  }

  sort_button(object_type, id, parent_id) {
    if (object_type !== 'task_template' || id === '') return '';

    return this.render_button(`sort_${object_type}`, 'sort', 'Sort');
  }

  move_button(object_type, id, parent_id) {
    if (object_type !== 'task_template' || id === '') return '';

    return this.render_button(`move_${object_type}`, 'swap-horizontal', 'Move');
  }

  delete_button(object_type, id, parent_id) {
    if (id === '') return '';

    const icon    = [ 'insight', 'resource' ].includes(object_type) ? 'link-off' : 'clear';
    const tooltip = [ 'insight', 'resource' ].includes(object_type) ? 'Unlink' : 'Delete';
    const authenticity_token = $('meta[name=csrf-token]').attr('content');
    return `<form action="/admineo/okrs/delete/${object_type}/${id}/${parent_id}" class="button_to" method="post">
              <input type="hidden" name="_method" value="delete">
              <input type="hidden" name="authenticity_token" value="${authenticity_token}">
              <button data-action="confirm:complete->okrs#delete" data-confirm="Are you sure?" title="${tooltip}" type="submit" class="btn btn-light">
                <i class="tio-${icon} text-danger"></i>
              </button>
            </form>`;
  }

  render_button(action, icon, tooltip, text_style = 'text-primary') {
    return `<button data-action="click->okrs#${action}" type="button" class="btn btn-light" title="${tooltip}">
              <i class="tio-${icon} ${text_style}"></i>
            </button>`;
  }

  link_to_backoffice(object_type, id) {
    if (id === '') return '';

    return `<a class="btn btn-light" rel="noopener" target="_blank" href="/admin/${object_type}s/${id}" title="Backoffice">
              <i class="tio-dashboard"></i>
            </a>`;
  }

  color_cells() {
    $(this.element).find('tr').each(function () {
      const $row = $(this);
      const data = $row.data('object-data');
      $row.find('td[data-field]').each(function () {
        const $cell = $(this);
        const field = $cell.data('field');
        if (!data[field]) {
          $cell.addClass('table-danger');
        } else if (!data['objective_active']) {
          $cell.addClass('table-warning');
        }
      });
    });
  }

}
